import {
  Heading,
  IconPlay,
  useMediumFromMediaQuery,
  useLargeFromMediaQuery,
} from '@madpaws/design-system';
import getConfig from 'next/config';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import { trackEvent } from '~/components/analytics/analytics';
import { CLICKED_HOW_IT_WORKS } from '~/components/analytics/constants';

import styles from './HowDoesMadPawsWork.module.css';
import { SuccessFlow } from '../SuccessFlow';
import {
  HMPW_ALT_TEXT,
  HMPW_TITLE,
  HMPW_YOUTUBE_LINK,
  HOW_DOES_MADPAWS_WORKS_HTML_ID,
} from '../constants';

import type { ReactElement } from 'react';

const { publicRuntimeConfig } = getConfig();

export const HowDoesMadPawsWork = (): ReactElement => {
  const isMediumFromViewport = useMediumFromMediaQuery();
  const isLargeFromViewport = useLargeFromMediaQuery();

  const handleClick = (): void => {
    trackEvent(CLICKED_HOW_IT_WORKS);
  };

  return (
    <section className={styles.root} id={HOW_DOES_MADPAWS_WORKS_HTML_ID}>
      <div className={styles.heading}>
        <Heading
          alignment="centerAlign"
          fontFamily="heading"
          size={isLargeFromViewport ? 'large1x' : isMediumFromViewport ? 'medium' : 'small1x'}
        >
          {HMPW_TITLE.replace('\n', ' ')}
        </Heading>
      </div>
      <div className={styles.teaser}>
        <div className={styles.image}>
          <ImageWithFallback
            alt={HMPW_ALT_TEXT}
            fallback={`${publicRuntimeConfig.staticPath}/images/homepage/CoupleWithDogsSmall.jpg`}
            webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/CoupleWithDogsSmall.webp`}
          />
        </div>
        <a
          aria-label="Check out how it works"
          className={styles.icon}
          href={HMPW_YOUTUBE_LINK}
          onClick={handleClick}
          rel="noreferrer"
          target="_blank"
        >
          <span className={styles.round} />
          <IconPlay size="large" />
        </a>
      </div>
      <SuccessFlow />
    </section>
  );
};
